import React from "react"

import Layout from "../components/layout"
import Hero from "../components/index/hero"
import Links from "../components/index/links"

import "../components/index/styles.css"

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <Links />
    </Layout>
  );
}

export default IndexPage
