import React from "react"

import { Link } from "gatsby";

const Links = ()  => {
  return (
    <>
      <div className="flex justify-center">
        <div className="flex flex-wrap justify-center mb-5 w-full lg:w-3/4">
          <Link to="/faq" className="link-button rounded my-3 py-5 w-3/4 text-center md:w-1/4 md:mx-4">
            <p>FAQ's Frequently Asked Questions</p>
          </Link>
          <Link to="/installation" className="link-button rounded my-3 py-5 w-3/4 text-center md:w-1/4 md:mx-4">
            <p>Installation</p>
          </Link>
          <Link to="/troubleshooting" className="link-button rounded my-3 py-5 w-3/4 text-center md:w-1/4 md:mx-4">
            <p>Troubleshooting</p>
          </Link>
          <Link to="/maintenance" className="link-button rounded my-3 py-5 w-3/4 text-center md:w-1/4 md:mx-4">
            <p>Maintenance</p>
          </Link>
          <Link to="/guides" className="link-button rounded my-3 py-5 w-3/4 text-center md:w-1/4 md:mx-4">
            <p>Guides</p>
          </Link>
          <Link to="/warranty-registration-help" className="link-button rounded my-3 py-5 w-3/4 text-center md:w-1/4 md:mx-4">
            <p>Warranty &ndash; Registration Help</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Links;
