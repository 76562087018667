import React from "react"

const Hero = () => {
  return (
    <>
      <div className="hero-bg flex justify-center items-center mb-5">
        <section>
          <h1 className="text-white text-4xl md:text-6xl">Summerset Support</h1>
        </section>
      </div>
    </>
  );
};

export default Hero;
